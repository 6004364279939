<template>
  <div v-if="!!organisationId && isLoggedIn">
    <Sheet v-if="false" :open="open" :modal="false" @update:open="closeSummary">
      <SheetContent class="w-full px-0 sm:min-w-[430px]">
        <!-- <SheetHeader v-if="bill?.currency !== 'GBP'" class="mx-6 border-b pb-5">
          <SheetTitle class="text-xl font-bold">Bill details</SheetTitle>
        </SheetHeader> -->
        <template v-if="!isFetchingBill">
          <SheetHeader
            class="mx-6 flex flex-row items-center gap-3 border-b pb-3"
          >
            <div class="flex-grow">
              <SheetTitle
                class="w-80 truncate text-left text-lg font-bold uppercase text-primary"
              >
                {{
                  bill?.beneficiary?.name ||
                  bill?.beneficiary?.trading_name ||
                  '-'
                }}
              </SheetTitle>
              <div class="mt-1.5 flex items-center justify-between">
                <div>
                  <div v-if="bill?.amount" class="text-sm text-primary/85">
                    {{ bill?.currency ? currencySymbol[bill.currency] : '-'
                    }}{{
                      bill?.amount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) || '-'
                    }}
                  </div>
                </div>
                <div>
                  <div
                    class="flex w-[max-content] items-center gap-x-1 rounded-full p-1 px-2 text-sm text-primary"
                  >
                    <div
                      :class="getBillStatusClasses(bill?.bill_status)"
                      class="h-2.5 w-2.5 rounded-full"
                    ></div>
                    {{ statusColors[bill?.bill_status || 'None'].value || '-' }}
                  </div>
                </div>
              </div>
            </div>
          </SheetHeader>
          <div class="mt-2">
            <ScrollArea
              class="bill-scroll h-[calc(100vh-153px)] w-full md:h-[calc(100vh-203px)]"
            >
              <div class="ml-3 w-[calc(100%-25px)] pb-10 sm:ml-6 sm:w-[382px]">
                <!-- <Summary
                  :bill="bill"
                  :preview="false"
                  @has-fetched-files="handleLoadingUploadedFiles"
                /> -->
                <ModulesBillsNewBillSummary
                  :bill="bill"
                  :preview="false"
                  :can-make-payment="!isBulkBillSelected"
                  @has-fetched-files="handleLoadingUploadedFiles"
                >
                  <template #payment-option>
                    <ModulesPaymentsPaymentLists
                      v-if="bill !== null"
                      :bill="bill"
                      @payment-option-picked="(val) => (paymentOption = val)"
                      @bank-account-selected="(val) => (bankAccount = val)"
                      @credit-card-selected="(val) => (creditCardId = val)"
                      @is-scheduled="(val) => (isScheduled = val)"
                      @gnpl-pricing-details-selected="
                        (val) => (gnplPricingDetails = val)
                      "
                      @fx-quote="(val) => (fxQuote = val)"
                      @scheduled-date-selected="(val) => (scheduleDate = val)"
                    />
                  </template>
                </ModulesBillsNewBillSummary>
              </div>
            </ScrollArea>
          </div>
          <SheetFooter
            v-if="!isBulkBillSelected"
            class="absolute bottom-0 left-0 z-20 w-full bg-white px-3 md:bottom-1 md:px-6"
          >
            <div class="w-full">
              <div
                v-if="
                  bill?.bill_status === 'ReadyForPayment' ||
                  bill?.bill_status === 'Canceled'
                "
                class="flex w-full items-center gap-x-3 border-t py-6"
              >
                <div
                  v-if="
                    permissionsNeededHere.canEditBill &&
                    permissionsNeededHere.canMakePayment
                  "
                  class="flex w-full items-center gap-3"
                >
                  <TooltipProvider>
                    <Tooltip :delay-duration="0">
                      <TooltipTrigger>
                        <Button
                          variant="outline"
                          class="w-full text-primary"
                          :disabled="isLoadingUploadedFiles"
                          @click="handleEditBill"
                        >
                          <ph-spinner
                            v-if="isLoadingUploadedFiles"
                            :size="16"
                            class="animate-spin"
                          />
                          <ph-pencil-simple-line v-else size="16" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent as="span"> Edit </TooltipContent>
                    </Tooltip>
                    <Tooltip
                      v-if="bill.bill_status === 'ReadyForPayment'"
                      :delay-duration="0"
                    >
                      <TooltipTrigger>
                        <Button
                          variant="outline"
                          class="w-full text-primary"
                          @click="onOpenReqApprovalForm"
                        >
                          <ph-stamp size="16" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent as="span">
                        Request Approval
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>

                  <Button
                    v-if="false"
                    :disabled="isPaying || paymentOption === undefined"
                    type="submit"
                    class="w-full"
                    @click.prevent="makePayment"
                  >
                    <ph-spinner
                      v-if="isPaying"
                      :size="24"
                      class="mr-2 animate-spin duration-1000"
                    />
                    {{
                      isPaying ? 'Starting payment...' : 'Make payment'
                    }}</Button
                  >
                  <Button
                    class="w-full"
                    :disabled="showriskConsent && !riskConsentAgreed"
                    @click.prevent="onOpenPaymentModal"
                  >
                    Make payment
                  </Button>
                </div>

                <template
                  v-else-if="
                    permissionsNeededHere.canEditBill &&
                    !permissionsNeededHere.canMakePayment
                  "
                >
                  <Button
                    :disabled="isLoadingUploadedFiles"
                    variant="outline"
                    class="w-full text-primary"
                    @click="handleEditBill"
                  >
                    Edit bill</Button
                  >

                  <Button
                    v-if="bill.bill_status === 'ReadyForPayment'"
                    :disabled="isLoadingUploadedFiles"
                    variant="default"
                    class="w-full"
                    @click="onOpenReqApprovalForm"
                  >
                    Request Approval</Button
                  >
                </template>
              </div>
              <div
                v-if="
                  permissionsNeededHere.canEditBill &&
                  bill?.bill_status === 'Draft'
                "
                class="flex w-full items-center gap-x-3 border-t py-6"
              >
                <Button
                  :disabled="isLoadingUploadedFiles"
                  class="w-full"
                  @click="handleEditBill"
                >
                  <the-icon
                    v-if="isLoadingUploadedFiles"
                    icon-name="spinner"
                    size="xs"
                    class-name="mr-1 animate-spin"
                  />
                  <the-icon
                    v-else
                    icon-name="pencil-edit"
                    size="xs"
                    class-name="fill-white stroke-white mr-1"
                  />
                  Update bill</Button
                >
              </div>

              <div
                v-if="
                  permissionsNeededHere.canApproveBill &&
                  bill?.bill_status === 'AwaitingApproval'
                "
                class="flex w-full items-center gap-x-3 border-t py-6"
              >
                <TooltipProvider>
                  <Tooltip :delay-duration="0">
                    <TooltipTrigger>
                      <Button
                        variant="outline"
                        class="w-full text-primary"
                        :disabled="isLoadingUploadedFiles"
                        @click="handleEditBill"
                      >
                        <ph-spinner
                          v-if="isLoadingUploadedFiles"
                          :size="16"
                          class="animate-spin"
                        />
                        <ph-pencil-simple-line v-else size="16" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent as="span"> Edit </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <Button
                  class="w-full"
                  variant="outline"
                  @click="onOpenDeclineApprovalForm"
                  >Decline Bill</Button
                >

                <Button
                  :disabled="isLoadingUploadedFiles || isApprovingOrRejecting"
                  class="w-full"
                  @click="approveBill(true)"
                >
                  <the-icon
                    v-if="isLoadingUploadedFiles"
                    icon-name="spinner"
                    size="xs"
                    class-name="mr-1 animate-spin"
                  />

                  <ph-spinner
                    v-if="isLoadingUploadedFiles || isApprovingOrRejecting"
                    :size="24"
                    class="mr-1 animate-spin"
                  />
                  {{ isApprovingOrRejecting ? 'Approving...' : 'Approve bill' }}
                </Button>
              </div>
            </div>
          </SheetFooter>
        </template>
        <template v-else>
          <div class="flex h-full w-full items-center justify-center">
            <full-page-loader label="" />
          </div>
        </template>
      </SheetContent>
      <!-- VERY DIRTY HACK, WE NEED TO START BUILDING OUR COMPONENTS, THE RESTRICTIONS ON THESE THINGS ARE A LOT -->
      <div
        v-if="open"
        class="fixed bottom-0 left-0 right-0 top-0 z-[50] bg-black/75 backdrop-blur-sm"
      ></div>
    </Sheet>
    <BaseSidePanel
      :show="open"
      content-max-width="max-w-[25rem]"
      @close-panel="closeSummary"
    >
      <template v-if="!isFetchingBill" #header>
        <div
          class="mx-4 flex flex-row items-center gap-3 border-b pb-3 pt-5"
          data-test-id="bill-summary-header"
        >
          <div class="flex-grow">
            <p
              class="flex w-80 items-center gap-1 truncate text-left text-lg font-bold uppercase text-primary"
            >
              <TooltipProvider
                v-if="
                  bill?.beneficiary?.account?.confirmation_of_payee
                    ?.is_full_match || bill?.beneficiary?.is_manually_verified
                "
                :delay-duration="0"
              >
                <Tooltip>
                  <TooltipTrigger>
                    <ph-check-circle
                      class="text-[#027A48]"
                      weight="fill"
                      :size="24"
                    />
                  </TooltipTrigger>
                  <TooltipContent class="text-sm" side="right">
                    This is a verified vendor
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              {{
                bill?.beneficiary?.name ||
                bill?.beneficiary?.trading_name ||
                '-'
              }}
            </p>
            <div class="mt-1.5 flex items-center justify-between">
              <div>
                <div v-if="bill?.amount" class="text-sm text-primary/85">
                  {{ bill?.currency ? currencySymbol[bill.currency] : '-'
                  }}{{
                    bill?.amount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) || '-'
                  }}
                </div>
              </div>
              <div>
                <div
                  class="flex w-[max-content] items-center gap-x-1 rounded-full p-1 px-2 text-sm text-primary"
                >
                  <div
                    :class="getBillStatusClasses(bill?.bill_status)"
                    class="h-2.5 w-2.5 rounded-full"
                  ></div>
                  {{ statusColors[bill?.bill_status || 'None'].value || '-' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="!isFetchingBill" #default>
        <div class="mt-2" data-test-id="bill-summary-body">
          <ScrollArea class="">
            <div class="ml-0 px-4 pb-10">
              <!-- <Summary
                  :bill="bill"
                  :preview="false"
                  @has-fetched-files="handleLoadingUploadedFiles"
                /> -->
              <ModulesBillsNewBillSummary
                :bill="bill"
                :preview="false"
                :can-make-payment="!isBulkBillSelected"
                @has-fetched-files="handleLoadingUploadedFiles"
              >
                <template #payment-option>
                  <ModulesPaymentsPaymentLists
                    v-if="bill !== null"
                    :bill="bill"
                    @payment-option-picked="(val) => (paymentOption = val)"
                    @bank-account-selected="(val) => (bankAccount = val)"
                    @credit-card-selected="(val) => (creditCardId = val)"
                    @is-scheduled="(val) => (isScheduled = val)"
                    @gnpl-pricing-details-selected="
                      (val) => (gnplPricingDetails = val)
                    "
                    @fx-quote="(val) => (fxQuote = val)"
                    @scheduled-date-selected="(val) => (scheduleDate = val)"
                  />
                </template>
              </ModulesBillsNewBillSummary>
            </div>
          </ScrollArea>
        </div>
      </template>

      <template v-if="!isFetchingBill" #footer>
        <div
          v-if="!isBulkBillSelected"
          data-test-id="bill-summary-footer"
          class="z-20 w-full border-t bg-white px-3 py-6 md:px-4"
        >
          <div
            v-if="
              permissionsNeededHere.canMakePayment &&
              (bill?.bill_status === 'ReadyForPayment' ||
                bill?.bill_status === 'Canceled') &&
              showriskConsent
            "
            class="mb-4"
          >
            <BaseCheckBox
              v-model="riskConsentAgreed"
              class="flex items-start gap-3 text-sm text-primary"
            >
              <span>
                I understand the risks of paying this vendor with unverified
                account details and wish to continue
              </span>
            </BaseCheckBox>
          </div>

          <div class="w-full">
            <div
              v-if="
                bill?.bill_status === 'ReadyForPayment' ||
                bill?.bill_status === 'Canceled'
              "
              class="flex w-full items-center gap-x-3"
            >
              <div
                v-if="
                  permissionsNeededHere.canEditBill &&
                  permissionsNeededHere.canMakePayment
                "
                class="flex w-full items-center gap-3"
              >
                <TooltipProvider>
                  <Tooltip :delay-duration="0">
                    <TooltipTrigger>
                      <Button
                        variant="outline"
                        class="w-full text-primary"
                        :disabled="isLoadingUploadedFiles"
                        @click="handleEditBill"
                      >
                        <ph-spinner
                          v-if="isLoadingUploadedFiles"
                          :size="16"
                          class="animate-spin"
                        />
                        <ph-pencil-simple-line v-else size="16" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent as="span"> Edit </TooltipContent>
                  </Tooltip>
                  <Tooltip
                    v-if="bill.bill_status === 'ReadyForPayment'"
                    :delay-duration="0"
                  >
                    <TooltipTrigger>
                      <Button
                        variant="outline"
                        class="w-full text-primary"
                        @click="onOpenReqApprovalForm"
                      >
                        <ph-stamp size="16" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent as="span">
                      Request Approval
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>

                <Button
                  v-if="false"
                  :disabled="
                    isPaying ||
                    paymentOption === undefined ||
                    (showriskConsent && !riskConsentAgreed)
                  "
                  type="submit"
                  class="w-full"
                  @click.prevent="makePayment"
                >
                  <ph-spinner
                    v-if="isPaying"
                    :size="24"
                    class="mr-2 animate-spin duration-1000"
                  />
                  {{
                    isPaying ? 'Starting payment...' : 'Make payment'
                  }}</Button
                >
                <Button
                  class="w-full"
                  :disabled="showriskConsent && !riskConsentAgreed"
                  @click.prevent="onOpenPaymentModal"
                >
                  Make payment
                </Button>
              </div>

              <template
                v-else-if="
                  permissionsNeededHere.canEditBill &&
                  !permissionsNeededHere.canMakePayment
                "
              >
                <Button
                  :disabled="isLoadingUploadedFiles"
                  variant="outline"
                  class="w-full text-primary"
                  @click="handleEditBill"
                >
                  Edit bill</Button
                >

                <Button
                  v-if="bill.bill_status === 'ReadyForPayment'"
                  :disabled="isLoadingUploadedFiles"
                  variant="default"
                  class="w-full"
                  @click="onOpenReqApprovalForm"
                >
                  Request Approval</Button
                >
              </template>
            </div>
            <div
              v-if="
                permissionsNeededHere.canEditBill &&
                bill?.bill_status === 'Draft'
              "
              class="flex w-full items-center gap-x-3 border-t py-6"
            >
              <Button
                :disabled="isLoadingUploadedFiles"
                class="w-full"
                @click="handleEditBill"
              >
                <the-icon
                  v-if="isLoadingUploadedFiles"
                  icon-name="spinner"
                  size="xs"
                  class-name="mr-1 animate-spin"
                />
                <the-icon
                  v-else
                  icon-name="pencil-edit"
                  size="xs"
                  class-name="fill-white stroke-white mr-1"
                />
                Update bill</Button
              >
            </div>

            <div
              v-if="
                permissionsNeededHere.canApproveBill &&
                bill?.bill_status === 'AwaitingApproval'
              "
              class="flex w-full items-center gap-x-3 border-t py-6"
            >
              <TooltipProvider>
                <Tooltip :delay-duration="0">
                  <TooltipTrigger>
                    <Button
                      variant="outline"
                      class="w-full text-primary"
                      :disabled="isLoadingUploadedFiles"
                      @click="handleEditBill"
                    >
                      <ph-spinner
                        v-if="isLoadingUploadedFiles"
                        :size="16"
                        class="animate-spin"
                      />
                      <ph-pencil-simple-line v-else size="16" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent as="span"> Edit </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <Button
                class="w-full"
                variant="outline"
                @click="onOpenDeclineApprovalForm"
                >Decline Bill</Button
              >

              <Button
                :disabled="isLoadingUploadedFiles || isApprovingOrRejecting"
                class="w-full"
                @click="approveBill(true)"
              >
                <the-icon
                  v-if="isLoadingUploadedFiles"
                  icon-name="spinner"
                  size="xs"
                  class-name="mr-1 animate-spin"
                />

                <ph-spinner
                  v-if="isLoadingUploadedFiles || isApprovingOrRejecting"
                  :size="24"
                  class="mr-1 animate-spin"
                />
                {{ isApprovingOrRejecting ? 'Approving...' : 'Approve bill' }}
              </Button>
            </div>
          </div>
        </div>
      </template>

      <div
        v-if="isFetchingBill"
        class="flex h-screen w-full items-center justify-center"
      >
        <full-page-loader label="" />
      </div>
    </BaseSidePanel>

    <ModulesPaymentsPaymentOtp
      :open="showOTPModal"
      :loading="isPaying"
      @close="showOTPModal = false"
      @update:get-pin="makeCardPayOrCreditFacilityPayment"
    />
    <template v-if="bill && reqApprovalFormIsOpen">
      <BaseModal
        class="z-[100]"
        :show="reqApprovalFormIsOpen"
        title="Request approval"
        @close-modal="onCloseReqApprovalForm"
      >
        <ModulesBillsRequestApprovalForm
          :bills="[bill]"
          class="mt-7"
          @success="
            () => {
              goToAwaitingApprovalBillsTableTab()
              onCloseReqApprovalForm()
            }
          "
        />
      </BaseModal>
    </template>

    <template v-if="bill && declineApprovalFormIsOpen">
      <BaseModal
        class="z-[100]"
        title="Decline bill"
        :show="declineApprovalFormIsOpen"
        @close-modal="onCloseDeclineApprovalForm"
      >
        <ModulesBillsDeclineApprovalForm
          :bills="[bill]"
          class="mt-7"
          @success="
            () => {
              goToUnpaidBillsTableTab()
              onCloseDeclineApprovalForm()
            }
          "
        />
      </BaseModal>
    </template>
    <template v-if="bill">
      <BaseModal
        :title="`Pay ${bill.amount} ${bill.currency}`"
        :show="paymentModalIsOpen"
        :can-close="!paymentModalIsBusy"
        @close-modal="onClosePaymentModal"
      >
        <template #title>
          <div class="space-y-1">
            <p>
              {{
                bill?.beneficiary?.name ||
                bill?.beneficiary?.trading_name ||
                '-'
              }}
            </p>
          </div>
        </template>
        <ModulesPaymentsNewPaymentList
          :bill="bill"
          @is-busy="setPaymentModalIsBusy"
          @success="
            () => {
              onClosePaymentModal()
              closeSummary()
            }
          "
          @edit-bill="handleEditBill"
        />
      </BaseModal>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import {
  PhPencilSimpleLine,
  PhStamp,
  PhSpinner,
  PhCheckCircle,
} from '@phosphor-icons/vue'
import dayjs from 'dayjs'
import type {
  BillStatusType,
  IBank,
  Invoice,
  PaymentOption,
} from '@/shared/interfaces'
import { ScrollArea } from '@/components/ui/scroll-area'
import FullPageLoader from '@/components/loaders/full-page-loader.vue'
// import Summary from '@/components/modules/bills/the-summary.vue'
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet'
import { currencySymbol } from '@/shared/utils'
import TheIcon from '@/components/shared/the-icon.vue'
import { useBillsStore } from '@/stores/bills'
import { routes } from '~/utils/routes'
import { useAuthStore } from '~/stores/auth'
import type { BillPaymentPricing } from '~/types/models/billPaymentLoans.model'
import usePaymentsComposable from '~/composables/usePaymentComposable'
import type { MakeSingleBillPaymentWithOpenBankingPayload } from '~/types/apiPayload/bills.payload'
import { toast } from '~/components/ui/toast'
import type { FXQuote } from '~/types/apiResponse/fx.payment'
import { LeadBoosterController } from '~/lib/utils'
const route = useRoute()
const router = useRouter()

const paymentOption = ref<PaymentOption>()
const gnplPricingDetails = ref<BillPaymentPricing>()
const fxQuote = ref<FXQuote>()
const scheduleDate = ref<string>(dayjs().add(1, 'day').toISOString())

const props = defineProps<{
  open: boolean
  bill: Invoice | null
  isFetchingBill: boolean
}>()

watch(
  () => props.open,
  (newVal) => {
    if (newVal) {
      LeadBoosterController.hide()
    }
  },
  { immediate: true },
)

onBeforeUnmount(() => {
  if (router.currentRoute.value.name === 'dashboard') {
    LeadBoosterController.show()
  }
})

const billStore = useBillsStore()
const bankAccount = ref<IBank>()
const creditCardId = ref<string>()
const isScheduled = ref(false)

const { Openbanking, CardPay, CreditFacility } = usePaymentsComposable()

const isBulkBillSelected = ref(false)
const authStore = useAuthStore()
const isPaying = ref(false)
const showOTPModal = ref(false)
const { personId } = storeToRefs(authStore)
const profileStore = useProfileStore()
const { organisationId } = storeToRefs(profileStore)
const { isLoggedIn } = storeToRefs(useAuthStore())

const { $event, $listen } = useNuxtApp()

$listen('open:bill-summary', ({ disablePayment }) => {
  isBulkBillSelected.value = disablePayment
})

// const { showPaymentModal, isLoading, makePayment, chosenPaymentType } =
//   usePayment({
//     closePayment: endPayment,
//   })

const statusColors = {
  Draft: { text: 'text-yellow-700', bg: 'bg-yellow-400', value: 'Draft' },
  ReadyForPayment: {
    text: 'text-blue-700',
    bg: 'bg-blue-400',
    value: 'Ready for payment',
  },
  Failed: {
    text: 'text-red-700',
    bg: 'bg-red-400',
    value: 'Failed',
  },
  AwaitingApproval: {
    text: 'text-[#f9f9f9]',
    bg: 'bg-[#F97316]',
    value: 'Awaiting approval',
  },
  Approved: {
    text: 'text-lime-700',
    bg: 'bg-lime-400',
    value: 'Approved',
  },
  Canceled: {
    text: 'text-slate-700',
    bg: 'bg-slate-400',
    value: 'Canceled',
  },
  Rejected: {
    text: 'text-red-700',
    bg: 'bg-red-400',
    value: 'Rejected',
  },
  Scheduled: {
    text: 'text-purple-700',
    bg: 'bg-purple-400',
    value: 'Scheduled',
  },
  Paid: {
    text: 'text-green-700',
    bg: 'bg-green-400',
    value: 'Paid',
  },
  Processing: {
    text: 'text-sky-700',
    bg: 'bg-sky-400',
    value: 'Processing',
  },
  None: {
    text: 'text-gray-700',
    bg: 'bg-gray-400',
    value: 'None',
  },
}

async function initiatePaymentWithOTP() {
  if (paymentOption.value === 'credit-card') {
    if (!creditCardId.value) {
      return toast({
        title: 'No credit card selected.',
        variant: 'destructive',
        description: 'Please select a card',
      })
    }
  }

  if (paymentOption.value === 'lenkie-card') {
    if (!gnplPricingDetails.value) {
      return toast({
        title: 'No Repayment periods',
        variant: 'destructive',
        description: 'Please select a repayment period',
      })
    }
  }

  if (!personId.value || !organisationId.value) return

  isPaying.value = true

  const { generateSessionCode, checkIfSessionDataExistOrValid, validate2fa } =
    CardPay

  try {
    if (!checkIfSessionDataExistOrValid()) {
      await generateSessionCode(() => (showOTPModal.value = true))
      return
    }

    const twoFaIsValid = await validate2fa()

    if (!twoFaIsValid) {
      await generateSessionCode(() => (showOTPModal.value = true))
      return
    }
    if (paymentOption.value === 'credit-card') {
      await finallyMakePaymentWithCardAfterChecks()
    } else if (paymentOption.value === 'lenkie-card') {
      await finallyMakePaymentWithCreditFacilityAfterChecks()
    }
  } catch (error) {
  } finally {
    isPaying.value = false
  }
}

function goToPaidTabAfterPayment() {
  $event('fetch:bills', {
    trigger: true,
    goToTab: 'Paid',
    params: { Page: 1 },
  })
}

function goToScheduledTabAfterPayment() {
  $event('fetch:bills', {
    trigger: true,
    goToTab: 'Scheduled',
    params: { Page: 1 },
  })
}
async function makeOpenBankingPayment() {
  if (!bankAccount.value)
    return toast({
      title: 'No bank selected.',
      variant: 'destructive',
      description: 'Please select/add a bank',
    })

  if (!personId.value || !props.bill) return

  const payload: MakeSingleBillPaymentWithOpenBankingPayload = {
    bill_id: props.bill.id,
    payment_request_data: {
      bank_account: bankAccount.value,
    },
    user: null,
    payment_type: 'OpenBank',
    agreed_to_pay_unverified_vendor: showriskConsent.value
      ? riskConsentAgreed.value
      : null,
  }

  try {
    $event('track:mixpanel', {
      event: 'Bills payment initiated (Open Banking)',
      data: props.bill,
    })
    isPaying.value = true
    await Openbanking.makeSingleBillsPaymentWithOpenBanking(payload)
  } catch (error) {
    isPaying.value = false
  } finally {
    isPaying.value = false
  }
}

async function finallyMakePaymentWithCardAfterChecks() {
  const { makeSingleBillPaymentWithCardPay } = CardPay

  if (!props.bill) {
    return
  }

  toast({
    title: 'Payment processing',
    description: 'We are processing your payments, please wait...',
  })

  try {
    isPaying.value = true
    const { status } = await makeSingleBillPaymentWithCardPay({
      payment_type: 'CardPay',
      payment_request_data: {
        payment_method_id: creditCardId.value!,
      },
      otp: null,
      session_code: null,
      user: null,
      bill_id: props.bill.id,
      agreed_to_pay_unverified_vendor: showriskConsent.value
        ? riskConsentAgreed.value
        : null,
    })

    if (status === 200) {
      $event('track:mixpanel', {
        event: 'CardPay payment successful',
        data: props.bill,
      })
      showOTPModal.value = false
      toast({
        title: 'Payment successful',
        variant: 'default',
        description: `Your payment was successful, please wait while we redirect you to the payments page`,
      })
      closeSummary()
      invalidateAllBillListQueries()
      goToPaidTabAfterPayment()
    }
  } catch (error: any) {
    $event('track:mixpanel', {
      event: 'CardPay payment failed',
      data: props.bill,
    })
    toast({
      variant: 'destructive',
      title: 'Payment failed',
      description:
        error?.response?.data?.error_message ||
        error?.response?.data?.failure_reasons?.['error-message']?.[0] ||
        'Error occurred',
    })
  } finally {
    isPaying.value = false
  }
}

const finallyMakePaymentWithCreditFacilityAfterChecks = async () => {
  const { makeSingleBillPaymentWithCreditFacility } = CreditFacility

  if (!gnplPricingDetails.value || !props.bill) {
    return
  }

  toast({
    title: 'Payment processing',
    description: 'We are processing your payments, please wait...',
  })

  try {
    isPaying.value = true
    const { status } = await makeSingleBillPaymentWithCreditFacility({
      payment_request_data: {
        // ...(props.bill?.currency !== 'GBP' && fxQuote.value && {
        //   fx: {
        //     fx_quote_id: fxQuote.value.quote_id,
        //     amount: fxQuote.value.buy_amount,
        //     currency: props.bill.currency
        //   }
        // }),
        fx: null,
        pricing: gnplPricingDetails.value,
        payment_date: isScheduled.value
          ? scheduleDate.value
          : dayjs().toISOString(),
      },
      user: null,
      payment_type: isScheduled.value ? 'ScheduleWithGnpl' : 'PayNowWithGnpl',
      bill_id: props.bill.id,
      agreed_to_pay_unverified_vendor: showriskConsent.value
        ? riskConsentAgreed.value
        : null,
    })

    if (status === 200) {
      showOTPModal.value = false
      // toast({
      //   title: 'Payment successful',
      //   variant: 'default',
      //   description: `Your payment was successful, please wait while we redirect you to the payments page`,
      // })

      $event('track:mixpanel', {
        event: 'GNPL payment successful',
        data: props.bill,
      })
      $event('track:mixpanel', {
        event: 'GNPL payment successful',
        data: props.bill,
      })
      closeSummary()
      invalidateAllBillListQueries()
      isScheduled.value
        ? goToScheduledTabAfterPayment()
        : goToPaidTabAfterPayment()
    }
  } catch (error: any) {
    $event('track:mixpanel', {
      event: 'GNPL payment failed',
      data: props.bill,
    })
    toast({
      variant: 'destructive',
      title: 'Payment failed',
      description:
        error?.response?.data?.error_message ||
        error?.response?.data?.failure_reasons?.['error-message']?.[0] ||
        'Error occurred',
    })
  } finally {
    isPaying.value = false
  }
}

async function makeCardPayOrCreditFacilityPayment(pin: number) {
  const { validate2fa } = CardPay
  $event('track:mixpanel', {
    event: `Bills payment initiated (${paymentOption.value === 'lenkie-card' ? 'GNPL' : 'Credit-Card'})`,
    data: { ...props.bill },
  })
  try {
    isPaying.value = true
    const twoFaIsValid = await validate2fa(pin.toString())
    if (!twoFaIsValid) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Wrong OTP code, please try again',
      })
      return
    }
    if (paymentOption.value === 'credit-card') {
      await finallyMakePaymentWithCardAfterChecks()
    } else if (paymentOption.value === 'lenkie-card') {
      await finallyMakePaymentWithCreditFacilityAfterChecks()
    }
  } catch (error) {
  } finally {
    isPaying.value = false
  }
}

const makePayment = () => {
  if (paymentOption.value === 'open-banking') {
    makeOpenBankingPayment()
  } else {
    initiatePaymentWithOTP()
  }
}

const getBillStatusClasses = (billStatus: BillStatusType | undefined) => {
  const statusInfo = statusColors[billStatus || 'None']

  return `${statusInfo.text} ${statusInfo.bg}`
}

const isLoadingUploadedFiles = ref(true)
const uploadedFiles = ref<(File | null)[]>([])

const emit = defineEmits(['close'])

const handleLoadingUploadedFiles = (value: {
  fetched: boolean
  files: File[] | null
}) => {
  isLoadingUploadedFiles.value = value.fetched

  if (value.files !== null) {
    uploadedFiles.value = value.files
  }
}

const canCloseSummary = computed(() => {
  return !reqApprovalFormIsOpen.value && !declineApprovalFormIsOpen.value
})

const closeSummary = () => {
  if (!canCloseSummary.value) return
  $event('track:mixpanel', {
    event: 'Bill Summary Closed',
    data: props.bill ? { ...props.bill } : {},
  })
  emit('close')
  onCloseReqApprovalForm()
}

const handleEditBill = () => {
  if (props.bill !== null) {
    billStore.setSelectedBill(props.bill)
    billStore.setSelectedBillInvoices(uploadedFiles.value)
    if (route.path !== routes.newBill) {
      closeSummary()
      onClosePaymentModal()
      router.push({
        path: routes.newBill,
        query: {
          id: props.bill.id,
        },
      })
    }
  }
}

function goToUnpaidBillsTableTab() {
  $event('fetch:bills', {
    trigger: true,
    goToTab: 'Unpaid',
    params: { Page: 1 },
  })
}

function goToAwaitingApprovalBillsTableTab() {
  $event('fetch:bills', {
    trigger: true,
    goToTab: 'Awaiting Approval',
    params: { Page: 1 },
  })
}

const {
  isOpen: reqApprovalFormIsOpen,
  onClose: onCloseReqApprovalForm,
  onOpen: onOpenReqApprovalForm,
} = useDisclosure()

const {
  isOpen: declineApprovalFormIsOpen,
  onClose: onCloseDeclineApprovalForm,
  onOpen: onOpenDeclineApprovalForm,
} = useDisclosure()

const { invalidateAllBillListQueries } = useQueryUtilitiesFns()

const { approveOrRejectBill } = useBills()

const { mutate: approveOrRejectFn, isPending: isApprovingOrRejecting } =
  approveOrRejectBill()

function approveBill(isApprove: boolean) {
  if (!props.bill || !personId.value) return
  approveOrRejectFn(
    {
      bill_ids: [props.bill.id],
      is_approve: isApprove,
      note: null,
      approval_id: personId.value.toString(),
    },
    {
      onSuccess() {
        invalidateAllBillListQueries()
        goToUnpaidBillsTableTab()
        $event('reload:bill-summary', null)
      },
    },
  )
}

const { currentPermissionsIds } = useUserRole()

const permissionsNeededHere = computed(() => {
  const permissionsSet = new Set(currentPermissionsIds.value)

  return {
    canMakePayment:
      permissionsSet.has('CardPay.MakePayment') ||
      permissionsSet.has('OpenBanking.MakePayment') ||
      permissionsSet.has('CreditFacility.MakePayment'),
    canEditBill: permissionsSet.has('Bills.CreateBill'),
    canApproveBill: permissionsSet.has('Bills.ApproveBills'),
  }
})

const showriskConsent = computed(() => {
  if (!props.bill) return false
  if (!props.bill.beneficiary?.account?.confirmation_of_payee) return false

  return (
    !props.bill.beneficiary.account.confirmation_of_payee.is_full_match &&
    !props.bill.beneficiary.is_manually_verified
  )
})
const riskConsentAgreed = ref(false)

const {
  onClosePaymentModal,
  onOpenPaymentModal,
  paymentModalIsBusy,
  paymentModalIsOpen,
  setPaymentModalIsBusy,
} = useDisclosure('paymentModal')
</script>
