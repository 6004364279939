<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { PhSpinner } from '@phosphor-icons/vue'
import type { Invoice } from '~/shared/interfaces'
import { useAuthStore } from '~/stores/auth'
import { useToast } from '~/components/ui/toast'

const { invalidateAllBillListQueries } = useQueryUtilitiesFns()

interface Props {
  bills: Invoice[]
}

interface Emits {
  (e: 'success'): void
  (e: 'isBusy', v: boolean): void
}

const authStore = useAuthStore()
const props = defineProps<Props>()
const emit = defineEmits<Emits>()
const { $api, $event } = useNuxtApp()
const { personId } = storeToRefs(authStore)
const { toast } = useToast()

const isMultiple = computed(() => props.bills.length > 1)

const form = reactive({
  note: '',
})

const { mutate: requestApprovalFn, isPending: isDeclining } = useMutation({
  mutationFn: $api.banking.bills.approveOrRejectBill,
})

function handleSubmit() {
  if (!personId.value) return
  const { note } = form
  const ids = props.bills.map((bill) => bill.id)
  emit('isBusy', true)
  requestApprovalFn(
    {
      note: note.trim() ? note : null,
      bill_ids: ids,
      approval_id: personId.value.toString(),
      is_approve: false,
    },
    {
      onSuccess() {
        toast({
          title: 'Approval Declined',
          description: isMultiple.value
            ? `Approval has been declined for ${ids.length} bills`
            : 'Approval has been declined!',
        })

        invalidateAllBillListQueries()
        $event('reload:bill-summary', null)
        emit('success')
      },
      onError() {
        // TODO: Handle error correctly
        toast({
          title: 'Error Occured',
          description: 'Error occured while trying to decline approval',
          variant: 'destructive',
          duration: 3000,
        })
      },

      onSettled() {
        emit('isBusy', false)
      },
    },
  )
}
</script>

<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <div class="mt-6">
        <Label
          for="reference"
          :class="false ? 'text-red-500' : ''"
          class="mb-1.5 text-sm font-medium text-primary"
        >
          Add a note (optional)
        </Label>
        <Textarea id="note" v-model.trim="form.note" />
      </div>

      <Button class="mt-7 w-full" :disabled="isDeclining">
        <ph-spinner
          v-if="isDeclining"
          :size="24"
          class="mr-2 animate-spin duration-1000"
        />
        Decline approval
      </Button>
    </form>
  </div>
</template>
