import { useMutation } from '@tanstack/vue-query'
import { useToast } from '~/components/ui/toast'

function approveOrRejectBill() {
  const { toast } = useToast()
  const { $api } = useNuxtApp()
  return useMutation({
    mutationFn: $api.banking.bills.approveOrRejectBill,
    onSuccess(_, _variables) {
      toast({
        title: 'Bill Approved',
        description: 'Bill approval successfull',
      })
    },
    onError() {
      toast({
        title: 'Error Occured',
        description: 'Error occured while approving bill',
        variant: 'destructive',
      })
    },
  })
}

export function useBills() {
  return {
    approveOrRejectBill,
  }
}
