<script setup lang="ts">
import { useMutation, useQuery } from '@tanstack/vue-query'
import useVuelidate from '@vuelidate/core'
import _ from 'lodash'
import { helpers, required } from '@vuelidate/validators'
import { PhSpinner } from '@phosphor-icons/vue'
import { USER_QUERY_KEYS } from '~/constants/queryKeys/usersQueryKeys'
import { vuelidateErrorMessage } from '~/lib/utils'
import type { Invoice } from '~/shared/interfaces'
import { useAuthStore } from '~/stores/auth'
import type { TeamMember } from '~/types/models/user.model'
import { useToast } from '~/components/ui/toast'

const { invalidateAllBillListQueries } = useQueryUtilitiesFns()

interface Props {
  bills: Invoice[]
}

interface Emits {
  (e: 'success'): void
  (e: 'isBusy', v: boolean): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()
const { $api, $event } = useNuxtApp()
const { toast } = useToast()
const profileStore = useProfileStore()
const authStore = useAuthStore()
const { organisationId, profile } = storeToRefs(profileStore)
const { personId } = storeToRefs(authStore)

const isMutliple = computed(() => props.bills.length > 1)

const form = reactive({
  approverId: '',
  note: '',
})

const rules: Record<keyof typeof form, object> = {
  approverId: {
    required: helpers.withMessage('Select a user', required),
  },
  note: {},
}

const v$ = useVuelidate(rules, form)

const {
  data: teamMembers,
  isLoading: isLoadingMembers,
  isFetching: isFetchingMembers,
} = useQuery({
  queryKey: USER_QUERY_KEYS.GET_ORGANIZATION_KEYS(
    organisationId.value as string,
  ),
  queryFn: () => $api.core.users.getOrganisationUsers(organisationId.value!),
  enabled: computed(() => !!organisationId.value),
  select(data) {
    return data.data
  },
})

function isCurrentlyLoggedInUser(member: TeamMember) {
  if (!profile.value) return false
  return member.email === profile.value.work_email_address
}

const possibleApprovers = computed(() => {
  if (!teamMembers.value || !profile.value) return []

  return teamMembers.value.filter((member) => {
    const permissionsIdsSet = new Set(
      member.role?.permissions.map((permission) => permission.id) || [],
    )

    return (
      !isCurrentlyLoggedInUser(member) &&
      permissionsIdsSet.has('Bills.ApproveBills')
    )
  })
})

/**
 *    <Select id="role" class="max-w-full">
      <SelectTrigger class="h-10 w-full">
        <SelectValue placeholder="Select a role">
          <!-- {{ form.role }} -->
        </SelectValue>
      </SelectTrigger>
      <SelectContent side="bottom" class="z-[5000000000] break-all">
        <!-- <template v-if="roles"> -->
        <SelectItem class="max-w-full cursor-pointer text-primary">
        </SelectItem>
        <!-- </template> -->
      </SelectContent>
    </Select>
 */

const { mutate: requestApprovalFn, isPending: isRequesting } = useMutation({
  mutationFn: $api.banking.bills.requestApproval,
})

function handleSubmit() {
  v$.value.$touch()
  if (v$.value.$invalid) return

  if (!personId.value) return
  const { approverId, note } = form
  const ids = props.bills.map((bill) => bill.id)
  emit('isBusy', true)
  requestApprovalFn(
    {
      approvals: [approverId],
      note: note.trim() ? note : null,
      requester_id: personId.value.toString(),
      bill_ids: ids,
    },
    {
      onSuccess() {
        toast({
          title: 'Request Approval Success',
          description: isMutliple.value
            ? `Approval has been requested for ${ids.length} bills`
            : 'Approval has been requested!',
        })

        $event('track:mixpanel', {
          event: 'Request Bill Approval',
          data: { billIds: ids },
        })
        invalidateAllBillListQueries()
        $event('reload:bill-summary', null)
        emit('success')
      },
      onError() {
        toast({
          title: 'Error Occured',
          description: 'Error occured while trying to request approval',
          variant: 'destructive',
          duration: 3000,
        })
      },
      onSettled() {
        emit('isBusy', false)
      },
    },
  )
}
</script>

<template>
  <div>
    <div v-if="isLoadingMembers || isFetchingMembers" class="mt-1">
      <div>
        <LoadersShimmerLoader class="h-16 rounded-md" />
      </div>
      <div class="mt-6">
        <LoadersShimmerLoader class="h-24 rounded-md" />
      </div>
      <div class="mt-7">
        <LoadersShimmerLoader class="h-10 rounded-md" />
      </div>
    </div>
    <form v-else @submit.prevent="handleSubmit">
      <div>
        <Label for="role" class="mb-1.5 text-sm font-medium text-[#132248]"
          >Select approver</Label
        >
        <Select id="role" v-model="v$.approverId.$model" class="max-w-full">
          <SelectTrigger class="h-10 w-full">
            <SelectValue placeholder="Select approver" />
          </SelectTrigger>
          <SelectContent side="bottom" class="z-[5000000000] break-all">
            <SelectItem
              v-for="member in possibleApprovers"
              :key="member.id"
              :value="member.id"
              class="max-w-full cursor-pointer text-primary"
            >
              {{ _.capitalize(member.first_name) }}
              {{ _.capitalize(member.last_name) }}
            </SelectItem>
          </SelectContent>
        </Select>
        <p
          v-if="
            v$.approverId.$error && vuelidateErrorMessage(v$.approverId.$errors)
          "
          class="mt-1.5 text-sm text-red-600"
        >
          {{ vuelidateErrorMessage(v$.approverId.$errors) }}
        </p>
      </div>

      <div class="mt-6">
        <Label
          for="reference"
          :class="false ? 'text-red-500' : ''"
          class="mb-1.5 text-sm font-medium text-primary"
        >
          Add a note (optional)
        </Label>
        <Textarea
          id="note"
          v-model.trim="v$.note.$model"
          placeholder="Send a note to the approver"
        />
        <p
          v-if="v$.note.$error && vuelidateErrorMessage(v$.note.$errors)"
          class="mt-1.5 text-sm text-red-600"
        >
          {{ vuelidateErrorMessage(v$.note.$errors) }}
        </p>
      </div>

      <Button class="mt-7 w-full" :disabled="isRequesting">
        <ph-spinner
          v-if="isRequesting"
          :size="24"
          class="mr-2 animate-spin duration-1000"
        />
        Submit request</Button
      >
    </form>
  </div>
</template>
