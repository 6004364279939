<template>
  <div>
    <div v-if="enablesPayment.length !== 0" class="pt-3">
      <div
        v-if="isValidating"
        class="z-50 grid cursor-not-allowed grid-rows-2 gap-3 bg-white"
      >
        <LoadersShimmerLoader
          v-for="i in 2"
          :key="i"
          class="h-[2.8125rem] w-full rounded-sm"
        />
      </div>
      <div
        v-else
        class="relative space-y-3"
        :class="{
          'pointer-events-none': isValidating,
        }"
      >
        <template v-for="option in enablesPayment" :key="option.value">
          <div v-if="!option.hide" class="relative">
            <Label
              class="mb-2 flex cursor-pointer rounded-[4px] border border-[#E2E8F0] p-3"
              :class="{
                'pointer-events-none opacity-40': !option.isAvailable.valid,
              }"
            >
              <div class="flex-1">
                <div class="flex items-center justify-between">
                  <div
                    class="inline-flex gap-x-2 text-sm font-medium text-primary"
                  >
                    <div><the-icon :icon-name="option.icon" size="xsm" /></div>
                    <div>{{ option.label }}</div>
                  </div>
                  <div>
                    <div class="flex items-center">
                      <input
                        id="default-radio-1"
                        v-model="selectedOption"
                        name="default-radio-1"
                        type="radio"
                        :value="option.value"
                        class="h-4 w-4 border-gray-300 bg-gray-100 ring-primary"
                        @change="emitSelectedValue(option.value)"
                      />
                    </div>
                  </div>
                </div>

                <template v-if="selectedOption === option.value">
                  <ModulesPaymentsPaymentListOpenBanking
                    v-if="selectedOption === 'open-banking'"
                    v-model="selectedBank"
                    v-model:bank-details="selectedBankDetails"
                    class="mt-6"
                  />
                  <ModulesPaymentsPaymentListCardPay
                    v-if="selectedOption === 'credit-card'"
                    v-model="cardSelected"
                    :amount="bill.amount"
                    class="mt-6"
                  />
                  <ModulesPaymentsPaymentLenkiePay
                    v-if="selectedOption === 'lenkie-card'"
                    v-model:price-id="priceId"
                    v-model:is-scheduled="isScheduled"
                    v-model:scheduled-date="scheduledDate"
                    :base-currency="bill.currency"
                    :can-schedule="bill.currency === 'GBP'"
                    :base-amount="bill.amount"
                    class="mt-6"
                    @fx-quote="(val) => (fxQuote = val)"
                    @pricing-details="(val) => (gnplPricingDetails = val)"
                  />
                </template>
              </div>
            </Label>

            <template v-if="option.isAvailable.message">
              <div
                class="group absolute bottom-0 left-0 right-0 top-0 z-[99999] cursor-pointer px-2"
              >
                <p
                  class="relative top-1 z-[9999] scale-0 transform cursor-text rounded-md border bg-white px-1 py-1 text-center text-sm shadow-md duration-100 group-hover:scale-100"
                >
                  {{ option.isAvailable.message }}
                </p>
              </div>
            </template>
          </div>
        </template>
      </div>

      <div>
        <div class="mt-4 border-t px-2 pt-4 text-center">
          By using this payment method, you agree to our <br />
          <a
            href="https://lenkie.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
            class="text-[#758EC6]"
            >terms of service</a
          >
        </div>
      </div>
    </div>
    <div v-else class="pt-3 text-center text-sm text-slate-500">
      This vendor is not enabled for payment
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { useQuery } from '@tanstack/vue-query'
import type {
  IBank,
  Invoice,
  PaymentOption,
  PaymentOptionsEmitInterface,
} from '@/shared/interfaces'
import TheIcon from '@/components/shared/the-icon.vue'
import { useProfileStore } from '~/stores/profile'
import { Label } from '@/components/ui/label'
import type { ValidatePaymentResponse } from '@/types/apiResponse/payments.response'
import type { BillPaymentPricing } from '~/types/models/billPaymentLoans.model'
import type { FXQuote } from '~/types/apiResponse/fx.payment'

const selectedOption = ref<PaymentOption>()
const props = defineProps<{
  bill: Invoice
}>()
const selectedCardValue = ref()

const selectedBank = ref<string>()
const fxQuote = ref<FXQuote>()

const { isGNPLUser } = storeToRefs(useProfileStore())
const cardSelected = ref()
const priceId = ref('')
const selectedBankDetails = ref<IBank>()
const isScheduled = ref(false)
const scheduledDate = ref<Date>(dayjs().add(1, 'day').toDate())
const gnplPricingDetails = ref<BillPaymentPricing>()
const hasInvoice = !!props.bill.invoice_url

const emit = defineEmits<PaymentOptionsEmitInterface>()

watch(selectedOption, (newVal) => {
  if (newVal) {
    emit('payment-option-picked', newVal)
  }
})

watch(
  selectedBankDetails,
  (newVal) => {
    if (newVal) {
      emit('bank-account-selected', newVal)
    }
  },
  { deep: true },
)

watch(
  fxQuote,
  (newVal) => {
    if (newVal) {
      emit('fx-quote', newVal)
    }
  },
  { deep: true },
)

watch(cardSelected, (newVal) => {
  if (newVal) {
    emit('credit-card-selected', newVal)
  }
})

watch(isScheduled, (newVal) => {
  emit('is-scheduled', newVal)
})

watch(scheduledDate, (newVal) => {
  if (newVal) {
    emit('scheduled-date-selected', newVal)
  }
})

watch(
  gnplPricingDetails,
  (newVal) => {
    if (newVal) {
      emit('gnpl-pricing-details-selected', newVal)
    }
  },
  { deep: true },
)

const enablesPayment = computed(() => {
  const availabilityAndMessage = validatedOptions.value.reduce(
    (accm, curr) => {
      let obj = {
        message: curr.message,
        valid: curr.is_valid,
      }

      if (curr.payment_method === 'CardPay') {
        obj = {
          ...obj,
          valid: obj.valid,
          message: obj.message ? obj.message : '',
        }
      }

      if (curr.payment_method === 'PayNowWithGnpl') {
        obj = {
          ...obj,
          valid: obj.valid && hasInvoice,
          message: obj.message
            ? obj.message
            : !hasInvoice
              ? 'No invoice(s) attached to this bill'
              : '',
        }
      }

      accm[curr.payment_method] = obj

      return accm
    },
    {
      CardPay: { message: '', valid: false },
      OpenBank: { message: '', valid: false },
      PayNowWithGnpl: { message: '', valid: false },
    } as Record<
      ValidatePaymentResponse[number]['payment_method'],
      { valid: boolean; message: string | null }
    >,
  )

  const options = [
    {
      value: 'open-banking',
      label: 'Pay via your bank',
      icon: 'bank',
      isAvailable: availabilityAndMessage.OpenBank,
    },
    {
      value: 'credit-card',
      label: 'Pay with credit card',
      icon: 'credit-card',
      isAvailable: availabilityAndMessage.CardPay,
      hide: true,
    },
    {
      value: 'lenkie-card',
      label: 'Pay with Lenkie',
      icon: 'wallet',
      isAvailable: availabilityAndMessage.PayNowWithGnpl,
      hide: !isGNPLUser.value,
    },
  ]

  return options
})

const { $event, $api } = useNuxtApp()

const emitSelectedValue = (option: string) => {
  $event('track:mixpanel', {
    event:
      option === 'lenkie-card'
        ? 'GNPL option selected'
        : `${option} option selected`,
    data: {},
  })

  $event('bill:payment', {
    trigger: true,
    bill: props.bill,
    paymentType: option,
  })
}

watch(
  () => selectedCardValue.value,
  (newValue) => {
    if (newValue) {
      $event('track:mixpanel', {
        event: 'Credit Card Selected',
        data: { ...newValue },
      })

      $event('select:card', {
        trigger: true,
        selectedCard: selectedCardValue.value,
      })
    }
  },
)

const { organisationId } = storeToRefs(useProfileStore())
const { data: validatedOptionsData, isLoading: isValidating } = useQuery({
  queryKey: ['validate-single-payment', { ...props.bill }],
  queryFn: () =>
    $api.banking.payments.validatePaymentMethods({
      amount: props.bill.amount,
      beneficiary_id: props.bill.beneficiary.id,
      organisation_id: organisationId.value!,
      currency_code: props.bill.currency,
    }),
  enabled: computed(
    () =>
      !!organisationId.value &&
      (props.bill.bill_status === 'ReadyForPayment' ||
        props.bill.bill_status === 'Canceled'),
  ),
  select(data) {
    return data.data
  },
})

const validatedOptions = computed(() => {
  return validatedOptionsData.value || []
})

onBeforeUnmount(() => {
  emit('payment-option-picked', undefined)
  emit('bank-account-selected', undefined)
  emit('credit-card-selected', undefined)
  emit('price-id-selected', undefined)
  emit('is-scheduled', false)
  emit('scheduled-date-selected', undefined)
  emit('gnpl-pricing-details-selected', undefined)
})
</script>
