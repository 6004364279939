<script setup lang="ts">
import gsap from 'gsap'
import { watchImmediate } from '@vueuse/core'
import { PhX } from '@phosphor-icons/vue'
interface Props {
  side?: 'left' | 'right'
  show: boolean
  canClose?: boolean
  contentMaxWidth?: string
  contentBackgroundColor?: string
}

interface Emits {
  (event: 'close-panel'): void
}

const props = withDefaults(defineProps<Props>(), {
  side: 'right',
  canClose: true,
  contentMaxWidth: 'max-w-[25rem]',
  contentBackgroundColor: 'bg-white',
})
const emit = defineEmits<Emits>()

const sheetContentRef = ref<HTMLDivElement>()
function closeSheet() {
  if (props.canClose) {
    return emit('close-panel')
  }
}

function animateSheet(ele: Element, done: () => void, isEntering: boolean) {
  const content = ele.querySelector('.sheet-content') as HTMLDivElement
  if (!content) return

  const side = props.side
  const contentAnimation: gsap.TweenVars = {
    x: isEntering ? '0%' : side === 'left' ? '-100%' : '100%',
  }
  const finalBackgroundColor = isEntering ? 'rgba(0,0,0,0.75)' : 'rgba(0,0,0,0)'

  const tl = gsap.timeline({
    onComplete: () => {
      done()
      if (isEntering) {
        content.addEventListener('keydown', closeOnESC)
        content.tabIndex = 0
        content.focus()
      } else {
        content.removeEventListener('keydown', closeOnESC)
      }
    },
    paused: true,
    defaults: { duration: 0.2, ease: 'none' },
  })

  if (isEntering) {
    // SET INITIAL POSITIONS
    tl.set(ele, { backgroundColor: 'rgba(0,0,0,0)' })
    tl.set(content, { x: side === 'left' ? '-100%' : '100%' })
  }

  tl.to(ele, { backgroundColor: finalBackgroundColor })
  tl.to(content, contentAnimation, '<')
  tl.play()
}

function onLeave(ele: Element, done: () => void) {
  animateSheet(ele, done, false)
}

function onEnter(ele: Element, done: () => void) {
  animateSheet(ele, done, true)
}

function closeOnESC(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    closeSheet()
  }
}

watchImmediate(
  () => props.show,
  (newVal) => {
    if (newVal) {
      document.body.classList.add('sidepanel-open')
    } else {
      document.body.classList.remove('sidepanel-open')
    }
  },
)
</script>

<template>
  <Teleport to="body">
    <Transition @enter="onEnter" @leave="onLeave">
      <div
        v-if="show"
        class="fixed bottom-0 left-0 right-0 top-0 z-50 bg-black/75"
        @click.self="closeSheet"
      >
        <div
          ref="sheetContentRef"
          class="custom-scrollbar sheet-content fixed grid h-full max-h-screen w-full grid-rows-[auto,1fr,auto] overflow-hidden max-sm:w-full max-sm:max-w-full"
          :class="[
            contentMaxWidth,
            contentBackgroundColor,
            {
              'left-0': side === 'left',
              'right-0': side === 'right',
            },
          ]"
          :tabindex="1"
        >
          <button
            class="absolute right-4 top-4 rounded-md p-0.5 transition-colors hover:bg-secondary"
            @click="closeSheet"
          >
            <ph-x :size="16" class="h-4 w-4 text-muted-foreground" />
          </button>
          <div v-if="$slots.header">
            <slot name="header" />
          </div>
          <div class="overflow-auto">
            <slot></slot>
          </div>
          <div v-if="$slots.footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style>
body.sidepanel-open {
  max-height: 100vh;
  overflow: hidden;

  /* pointer-events: auto !important; */
}
</style>
