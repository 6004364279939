<script setup lang="ts" generic="K extends string">
interface Props {
  tabs: readonly K[]
}
defineProps<Props>()
const model = defineModel<K>({ required: true })

function handleClick(tab: K) {
  model.value = tab
}
</script>

<template>
  <div
    class="flex h-10 w-full items-center justify-center rounded-md bg-muted p-1 text-muted-foreground"
  >
    <button
      v-for="(tab, idx) in tabs"
      :key="idx"
      class="w-full cursor-pointer items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
      :class="[
        {
          'bg-background text-foreground shadow-sm': model === tab,
        },
      ]"
      @click="handleClick(tab)"
    >
      <slot :record="tab" :is-active="model === tab">
        {{ tab }}
      </slot>
    </button>
  </div>
</template>
