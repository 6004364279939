import type { CurrencyType } from '~/shared/interfaces'

const currencyMap = {
  GBP: '£',
  USD: '$',
  EUR: '€',
} as const

export const getCurrency = (currencyType: CurrencyType) => {
  if (currencyType === 'Other' || currencyType === null) return ''
  return currencyMap[currencyType]
}
