<script setup lang="ts">
import dayjs from 'dayjs'
import { isLastInArray } from '~/lib/utils'
import type { Invoice } from '~/shared/interfaces'

interface Props {
  notes: Invoice['notes']
}

const props = defineProps<Props>()

const numberOfNotesToDisplay = ref(3)

const displayedNotes = computed(() => {
  return [...props.notes].splice(0, numberOfNotesToDisplay.value)
})

const hasMore = computed(
  () => numberOfNotesToDisplay.value < props.notes.length,
)

function increaseWhatToSee() {
  numberOfNotesToDisplay.value += 3
}
</script>

<template>
  <div class="relative space-y-3">
    <div
      v-for="(note, idx) in displayedNotes"
      :key="idx"
      class="border-b pb-4"
      :class="[
        {
          'border-b-0': isLastInArray(notes, idx),
        },
      ]"
    >
      <div>
        <p class="font-medium text-primary">
          {{ note.person.given_name }} {{ note.person.family_name }}
        </p>
        <p class="text-xs font-medium text-[#94A3B8]">
          {{ dayjs(note.date_created).format('D/MM/YYYY h:mm a') }}
        </p>
        <p class="mt-2 text-sm font-normal text-primary">{{ note.note }}</p>
      </div>
    </div>
    <div
      v-if="hasMore"
      class="absolute bottom-0 z-10 flex w-full justify-center"
    >
      <div class="inv-gradient flex w-full justify-center to-transparent py-3">
        <Button
          variant="ghost"
          size="sm"
          class="text-primary"
          @click="increaseWhatToSee"
          >View more</Button
        >
      </div>
    </div>
  </div>
</template>
