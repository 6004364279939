<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query'
import dayjs from 'dayjs'
import { CalendarIcon } from 'lucide-vue-next'
import { watchDeep } from '@vueuse/core'
import { cn } from '@/lib/utils'
import { Calendar } from '@/components/ui/calendar'
import type { CurrencyType } from '~/shared/interfaces'
import type { FXQuote } from '~/types/apiResponse/fx.payment'
import type { BillPaymentPricing } from '~/types/models/billPaymentLoans.model'

interface Props {
  baseAmount: number
  canSchedule?: boolean
  baseCurrency: CurrencyType
}

interface Emits {
  (e: 'fxQuote', v: FXQuote | undefined): void
  (e: 'pricingDetails', v: BillPaymentPricing): void
}

const props = withDefaults(defineProps<Props>(), { canSchedule: true })
const emit = defineEmits<Emits>()

const minDate = (() => {
  const tomorrow = new Date()
  return tomorrow.setDate(tomorrow.getDate() + 1)
})()

const maxDate = (() => {
  const maxDay = new Date()
  return maxDay.setDate(maxDay.getDate() + 30)
})()

const selectedPriceId = defineModel<string>('priceId')
const isScheduled = defineModel<boolean>('isScheduled', { default: false })
const scheduledDate = defineModel<string | number | Date>('scheduledDate')
const openScheduleOrCloseCalendar = ref(false)
const todayFormatted = dayjs(new Date()).format('DD MMM YYYY')

watch(scheduledDate, (_newVal) => {
  openScheduleOrCloseCalendar.value = false
})

const selectedPriceDetail = computed(() => {
  if (selectedPriceId.value) {
    return (
      pricingList.value.find((price) => price.id === selectedPriceId.value) ||
      null
    )
  }
  return null
})

const repaymentPeriodMap: { [key: string]: number } = {
  '1 Month': 1,
  '2 Months': 2,
  '3 Months': 3,
  '4 Months': 4,
  '5 Months': 5,
  '6 Months': 6,
  '7 Months': 7,
  '8 Months': 8,
  '9 Months': 9,
  '10 Months': 10,
  '11 Months': 11,
  '12 Months': 12,
}

const { $api, $event } = useNuxtApp()
const profileStore = useProfileStore()
const { organisationId } = storeToRefs(profileStore)

const { data: fxQuote } = useQuery({
  queryKey: [
    'getFXQuote',
    {
      organisationId: organisationId.value,
      amount: props.baseAmount,
      currency: props.baseCurrency,
    },
  ],
  queryFn: () =>
    $api.banking.fx.generateFXQuote({
      organisation_id: organisationId.value!,
      quote_id: Guid.newGuid(),
      buy_amount: props.baseAmount,
      buy_currency: { code: props.baseCurrency },
    }),
  select(data) {
    return data.data
  },
  enabled: computed(() => {
    if (
      props.baseCurrency === 'GBP' ||
      props.baseCurrency === 'Other' ||
      !props.baseCurrency?.trim()
    )
      return false
    if (!organisationId.value) return false

    return true
  }),
})

const amountCharged = computed(() => {
  if (props.baseCurrency === 'GBP') return props.baseAmount

  if (!fxQuote.value) return 0

  if (props.baseCurrency === 'USD') {
    return props.baseAmount / fxQuote.value.lenkie_rate
  }

  if (props.baseCurrency === 'EUR') {
    return props.baseAmount * fxQuote.value.lenkie_rate
  }

  return 0
})

const { data: normalPricingDetails } = useQuery({
  queryKey: ['getBillPaymentPricingDetails'],
  queryFn: () =>
    $api.banking.billPaymentLoans.getBillPaymentPricingDetails({
      organisationId: organisationId.value!,
    }),

  enabled: computed(() => {
    if (!organisationId.value) return false
    if (isScheduled.value) return false
    return true
  }),

  select(data) {
    return data.data
  },
})

const {
  data: scheduledPricingDetails,
  isFetching: isFetchingScheduledPricingDetails,
} = useQuery({
  queryKey: ['getBillPaymentPricingDetails', scheduledDate],
  queryFn: () =>
    $api.banking.billPaymentLoans.getBillPaymentPricingDetails({
      organisationId: organisationId.value!,
      paymentDate: dayjs(scheduledDate.value!).format('YYYY-MM-DD'),
    }),

  enabled: computed(() => {
    if (!organisationId.value || !isScheduled.value) return false
    return true
  }),

  select(data) {
    return data.data
  },
})

const pricingList = computed(() => {
  if (isScheduled.value) {
    return scheduledPricingDetails.value || []
  } else {
    return normalPricingDetails.value || []
  }
})

const computedDetails = computed(() => {
  if (!selectedPriceDetail.value) return null
  const priceDetail = selectedPriceDetail.value

  const numberOfMonths =
    repaymentPeriodMap[selectedPriceDetail.value.repayment_period]

  const repaymentPeriod = priceDetail.repayment_period
  const fees = priceDetail.fee * amountCharged.value
  const totalRepayable = amountCharged.value + fees
  const monthlyPayment = totalRepayable / numberOfMonths

  const firstRepaymentDate = dayjs(priceDetail.first_repayment_date).format(
    'DD MMM YYYY',
  )

  const lastRepaymentData = dayjs(priceDetail.final_repayment_date).format(
    'DD MMM YYYY',
  )

  return {
    numberOfMonths,
    repaymentPeriod,
    fees,
    totalRepayable,
    monthlyPayment,
    dates: { firstRepaymentDate, lastRepaymentData },
  }
})

watch(
  fxQuote,
  (newVal) => {
    emit('fxQuote', newVal)
  },
  { deep: true, immediate: true },
)

watch(
  selectedPriceDetail,
  (newVal) => {
    if (newVal) {
      emit('pricingDetails', newVal)
    }
  },
  { deep: true, immediate: true },
)

watchDeep(computedDetails, (newVal) => {
  if (!newVal) return
  $event('track:mixpanel', {
    event: `GNPL repayment duration selected`,
    data: newVal,
  })
})
</script>

<template>
  <div class="mb-2 mt-6">
    <div
      v-if="canSchedule"
      :class="isScheduled ? '' : 'mb-2 border-b'"
      class="flex w-full items-center justify-between border-t px-1 py-2"
    >
      <div>
        <Label for="scheduled" class="text-sm font-medium text-[#64748B]"
          >Schedule payment</Label
        >
      </div>
      <div>
        <label class="relative inline-flex cursor-pointer items-center">
          <input
            v-model="isScheduled"
            type="checkbox"
            value=""
            class="peer sr-only"
          />
          <div
            class="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-[#1A2D5B] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"
          ></div>
        </label>
      </div>
    </div>
    <div v-if="isScheduled" class="mb-4">
      <div class="relative">
        <Popover :open="openScheduleOrCloseCalendar">
          <PopoverTrigger as-child @click="openScheduleOrCloseCalendar = true">
            <Button
              id="date"
              :variant="'outline'"
              :class="cn('mt-1.5 w-full justify-start text-left font-normal')"
            >
              <CalendarIcon class="mr-2 h-4 w-4" />
              <span>
                {{
                  scheduledDate
                    ? dayjs(scheduledDate).format('MMM DD, YYYY')
                    : 'Please pick a date'
                }}
              </span>
            </Button>
          </PopoverTrigger>
          <PopoverContent
            class="w-auto p-0"
            align="end"
            :avoid-collisions="true"
          >
            <Calendar
              v-model="scheduledDate"
              type="single"
              :columns="1"
              :min-date="minDate"
              :max-date="maxDate"
              is-required
            />
          </PopoverContent>
        </Popover>
      </div>
    </div>
    <p class="pb-[6px] text-sm font-medium text-primary">
      Select repayment period
    </p>
    <Select v-model="selectedPriceId" class="capitalize">
      <SelectTrigger class="">
        <SelectValue
          :placeholder="
            isFetchingScheduledPricingDetails ? 'Loading...' : 'Select period'
          "
        />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem
            v-for="price in pricingList"
            :key="price.id"
            :value="price.id"
          >
            <div class="text-sm capitalize text-[#334155]">
              {{ price.repayment_period }}
            </div>
          </SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
    <div class="mt-4 grid grid-cols-2 gap-x-3">
      <div :key="selectedPriceId" class="relative">
        <p class="pb-1.5 text-sm font-medium text-primary">
          First repayment date
        </p>
        <Input
          id="first_repayment_date"
          :model-value="
            computedDetails?.dates.firstRepaymentDate || todayFormatted
          "
          disabled
          type="text"
        />
        <SharedTheIcon
          icon-name="check-date"
          size="xsm"
          class-name="fill-[#343330] absolute right-2 top-9 opacity-50"
        />
      </div>
      <div class="relative">
        <p class="pb-1.5 text-sm font-medium text-primary">
          Last repayment date
        </p>
        <Input
          id="first_repayment_date"
          :model-value="
            computedDetails?.dates.lastRepaymentData || todayFormatted
          "
          disabled
          type="text"
        />
        <SharedTheIcon
          icon-name="check-date"
          size="xsm"
          class-name="fill-[#343330] absolute right-2 top-9 opacity-50"
        />
      </div>
    </div>
    <div class="mt-6 flex flex-col gap-y-3">
      <p class="text-sm font-medium text-primary">Transaction overview</p>
      <div class="flex items-center justify-between">
        <p class="text-sm font-normal text-primary">
          Invoice amount (excl. fee)
        </p>
        <p class="text-sm font-medium text-[#334155]">
          {{
            new Intl.NumberFormat('en-GB', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 2,
            }).format(amountCharged)
          }}
        </p>
      </div>
      <div class="flex items-center justify-between">
        <p class="text-sm font-normal text-primary">Fee</p>
        <p class="text-sm font-medium text-[#334155]">
          {{
            new Intl.NumberFormat('en-GB', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 2,
            }).format(computedDetails?.fees || 0)
          }}
        </p>
      </div>
      <div class="flex items-center justify-between">
        <p class="text-sm font-normal text-primary">Total repayable</p>
        <p class="text-sm font-medium text-[#334155]">
          {{
            new Intl.NumberFormat('en-GB', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 2,
            }).format(computedDetails?.totalRepayable || 0)
          }}
        </p>
      </div>
      <div class="flex items-center justify-between">
        <p class="text-sm font-normal text-primary">Monthly repayable</p>
        <p class="text-sm font-medium text-[#334155]">
          {{
            new Intl.NumberFormat('en-GB', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 2,
            }).format(computedDetails?.monthlyPayment || 0)
          }}
        </p>
      </div>
    </div>
  </div>
</template>
