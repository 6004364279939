<template>
  <div>
    <div
      v-if="bill?.bill_status === 'Draft'"
      class="mb-3 flex items-center space-x-3 rounded-xl bg-[#F4B24C1A] px-4 py-2"
    >
      <div>
        <the-icon icon-name="warning-icon" size="s" class-name="fill-none" />
      </div>

      <div>
        <p class="text-sm font-medium text-[#DC6803]">
          Some of the details required for this payment are missing.
          <button
            v-if="!isLoadingUploadedFiles"
            type="button"
            class="m-0 p-0 font-bold text-primary underline"
            @click="handleEditBill"
          >
            Update bill
          </button>
        </p>
      </div>
    </div>
    <div
      v-if="
        (bill?.bill_status === 'Draft' ||
          bill?.bill_status === 'ReadyForPayment') &&
        bill?.possible_duplicates &&
        bill.possible_duplicates?.length
      "
      class="mb-2 flex items-center space-x-3 rounded-lg bg-[#F4B24C1A] px-4 py-2"
    >
      <div>
        <the-icon icon-name="warning-icon" size="s" class-name="fill-none" />
      </div>

      <div>
        <div class="text-sm font-medium text-[#DC6803]">
          This bill might be a duplicate of bill no:
          <button
            v-for="(duplicate, ind) in bill.possible_duplicates"
            :key="`${duplicate?.bill_id} - ${duplicate?.invoice_id}`"
            class="cursor-pointer text-[#3F558E]"
            type="button"
            @click="goToDuplicateBill(duplicate.bill_id)"
          >
            <span class="underline">{{ `#${duplicate.invoice_id}` }}</span
            ><span v-if="ind !== bill.possible_duplicates.length - 1">,</span>
          </button>
        </div>
      </div>
    </div>
    <ModulesBillsPayeeConfirmationMessage
      v-if="
        bill?.beneficiary?.account?.confirmation_of_payee &&
        !bill?.beneficiary?.account?.confirmation_of_payee?.is_full_match &&
        !bill?.beneficiary?.is_manually_verified
      "
      class="my-2"
      :confirmation="bill?.beneficiary.account.confirmation_of_payee"
    />
    <div class="rounded-lg bg-[#F3F7FF] px-4 py-2">
      <div
        class="flex w-full justify-between gap-4 border-b border-slate-200 py-1"
      >
        <div class="whitespace-nowrap text-sm text-primary">Vendor name</div>
        <TooltipProvider>
          <Tooltip :delay-duration="0">
            <TooltipTrigger
              as="div"
              class="truncate text-sm font-medium text-primary"
            >
              {{
                bill?.beneficiary?.name ||
                bill?.beneficiary?.trading_name ||
                '-'
              }}
            </TooltipTrigger>
            <TooltipContent
              class="py-1 text-xs font-semibold text-[#344054]"
              as="p"
              side="top"
              align="end"
            >
              {{
                bill?.beneficiary?.name ||
                bill?.beneficiary?.trading_name ||
                '-'
              }}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div v-if="!bill?.payment_data">
        <div
          class="text-prim mt-2 flex w-full justify-between border-b border-slate-200 py-1 text-sm"
        >
          <div class="text-primary">Bank name</div>
          <div class="w-1/2 truncate text-right font-medium text-primary">
            {{ bill?.beneficiary?.account?.bank?.name || '-' }}
          </div>
        </div>
        <template v-if="mappedAccount.length !== 0">
          <div
            v-for="(item, index) in mappedAccount"
            :key="index"
            class="mt-2 flex items-center justify-between border-b py-1 text-sm text-primary last:border-none"
          >
            <div>{{ item.key }}</div>
            <div class="w-1/2 truncate text-right font-medium">
              {{ item.value || '-' }}
            </div>
          </div>
        </template>
      </div>
      <div
        v-if="bill?.payment_data?.account_number"
        class="mt-2 flex w-full justify-between border-b border-slate-200 py-1"
      >
        <div class="text-sm text-primary">Account Number</div>
        <div class="text-right text-sm font-medium text-primary">
          {{ bill?.payment_data?.account_number || '-' }}
        </div>
      </div>
      <div
        v-if="bill?.payment_data?.sort_code"
        class="mt-2 flex w-full justify-between py-1"
      >
        <div class="text-sm text-primary">Sort code</div>
        <div class="w-1/2 truncate text-right text-sm font-medium text-primary">
          {{
            (bill?.payment_data?.sort_code || '-')
              .match(/.{1,2}/g)
              ?.join('-') || '-'
          }}
        </div>
      </div>

      <template v-if="canUpdateGBPVendors">
        <div class="my-2 flex items-center gap-x-1">
          <div class="text-sm text-primary">Incorrect payment details?</div>
          <div>
            <button
              type="button"
              :disabled="isLoadingUploadedFiles"
              class="border-primary bg-[#F3F7FF] text-sm font-bold text-primary underline hover:bg-[#F3F7FF]"
              @click="openEditVendor"
            >
              Edit details
            </button>
          </div>
        </div>
      </template>
    </div>
    <Tabs v-model="activeTab" class="mt-5">
      <TabsList v-if="showApprovalDetailsTab" class="w-full">
        <TabsTrigger v-for="tab in tabs" :key="tab" :value="tab" class="w-full">
          {{ tab }}
        </TabsTrigger>
      </TabsList>

      <TabsContent :value="tabs[0]">
        <template v-if="true">
          <div class="mt-3">
            <div
              v-show="!showApprovalDetailsTab"
              class="text-sm font-bold text-primary"
            >
              Invoice details
            </div>
            <div class="mt-2">
              <div
                class="flex items-center justify-between border-b py-2 text-sm text-primary"
              >
                <div>Invoice ID</div>
                <div class="w-2/3 truncate text-right font-medium">
                  {{ bill?.invoice_id || bill?.invoice_id || '-' }}
                </div>
              </div>
              <div
                v-if="bill?.fee_amount"
                class="flex items-center justify-between border-b py-2 text-sm text-primary"
              >
                <div>Fee</div>
                <div class="w-1/2 truncate text-right font-medium">
                  {{ bill?.currency || '-' }}
                  {{
                    bill?.fee_amount?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) || '-'
                  }}
                </div>
              </div>
              <div
                class="flex items-center justify-between border-b py-2 text-sm text-primary"
              >
                <div>Date created</div>
                <div class="w-2/3 truncate text-right font-medium">
                  {{ bill?.date_created ? formatDate(bill.date_created) : '-' }}
                </div>
              </div>
              <div
                v-if="
                  bill?.date_paid &&
                  (bill?.bill_status === 'Paid' ||
                    bill?.bill_status === 'Failed' ||
                    bill?.bill_status === 'Processing')
                "
                class="flex items-center justify-between border-b py-2 text-sm text-primary"
              >
                <div>Date paid</div>
                <div class="w-2/3 truncate text-right font-medium">
                  {{ bill?.date_paid ? formatDate(bill.date_paid) : '-' }}
                </div>
              </div>
              <div
                class="flex items-center justify-between border-b py-2 text-sm text-primary"
              >
                <div>Created by</div>
                <div class="w-2/3 truncate text-right font-medium capitalize">
                  {{ bill?.person?.name ? bill.person.name : '-' }}
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3 text-sm text-primary">
            <div class="font-bold">Reference</div>
            <div class="mt-1">{{ bill?.reference || '-' }}</div>
          </div>
          <div v-if="bill?.payment_method" class="mt-3">
            <div class="text-sm font-bold text-primary">Payment method</div>
            <div
              v-if="bill?.payment_method === 'CardPay'"
              class="py-2 text-sm text-primary"
            >
              CardPay
            </div>
            <div
              v-if="bill?.payment_method === 'OpenBank'"
              class="py-2 text-sm text-primary"
            >
              Bank transfer
            </div>
            <div
              v-if="
                bill?.payment_method === 'PayNowWithGnpl' ||
                bill?.payment_method === 'ScheduleWithGnpl'
              "
              class="py-2 text-sm text-primary"
            >
              Credit facility
            </div>
          </div>
          <div v-if="props.bill?.invoice_url && !preview" class="mt-3">
            <template v-if="isLoadingUploadedFiles">
              <Skeleton class="h-[46px] w-full rounded-md" />
            </template>
            <template v-else>
              <div v-if="uploadedFiles.length" class="relative z-20">
                <div
                  v-if="
                    fileCount < uploadedFiles.length &&
                    uploadedFiles.length >= 3
                  "
                  class="absolute bottom-0 z-10 flex w-full items-end"
                >
                  <div
                    class="inv-gradient flex w-full justify-center to-transparent py-3"
                  >
                    <Button
                      variant="ghost"
                      size="sm"
                      class="text-primary"
                      @click="showMoreInvoices"
                      >View more</Button
                    >
                  </div>
                </div>
                <div class="space-y-1.5">
                  <div
                    v-for="file in uploadedFiles.slice(0, fileCount)"
                    :key="file?.name"
                    class="grid grid-cols-3 justify-between overflow-hidden rounded-md border p-2 px-2 duration-200 hover:ring-2 hover:ring-primary"
                  >
                    <div class="col-span-2 flex items-center">
                      <div>
                        <div v-if="file?.type === 'application/pdf'">
                          <the-icon
                            icon-name="pdf-file"
                            size="m"
                            class-name="fill-none"
                          />
                        </div>
                        <div v-else-if="file?.type === 'text/csv'">
                          <the-icon
                            icon-name="csv-file"
                            size="m"
                            class-name="fill-none"
                          />
                        </div>
                        <div v-else-if="file?.type === 'image/jpeg'">
                          <the-icon
                            icon-name="png-file"
                            size="m"
                            class-name="fill-none"
                          />
                        </div>
                        <div v-else-if="file?.type === 'image/png'">
                          <the-icon
                            icon-name="png-file"
                            size="m"
                            class-name="fill-none"
                          />
                        </div>
                        <div
                          v-else-if="
                            file?.type ===
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                          "
                        >
                          <the-icon
                            icon-name="doc-file"
                            size="m"
                            class-name="fill-none"
                          />
                        </div>
                        <div v-else>
                          <the-icon
                            icon-name="page-file"
                            size="m"
                            class-name="fill-none"
                          />
                        </div>
                      </div>
                      <div
                        class="ml-1.5 line-clamp-1 text-base font-medium text-primary"
                      >
                        {{ file?.name }}
                      </div>
                    </div>
                    <div class="flex items-center justify-end">
                      <Button
                        variant="ghost"
                        size="icon"
                        class="h-9 text-primary"
                        @click="() => downloadFile(file)"
                      >
                        <the-icon
                          icon-name="download-file"
                          size="xsm"
                          class-name="fill-none"
                      /></Button>
                      <Button
                        variant="ghost"
                        size="sm"
                        class="text-primary"
                        @click="() => handleSelectedInvoice(file)"
                        >View</Button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="text-sm text-red-400">
                Could not download file(s)
              </div>
            </template>
          </div>
          <Accordion
            v-if="canMakePayment && !!0"
            v-model:model-value="activeSection"
            type="single"
            class="w-full"
            collapsible
          >
            <template v-if="permissionsNeededHere.canSeePaymentOption">
              <AccordionItem
                v-if="
                  bill !== null &&
                  (bill.bill_status === 'ReadyForPayment' ||
                    bill.bill_status === 'Canceled')
                "
                value="item-2"
                class="mt-4 rounded-lg border"
              >
                <AccordionTrigger
                  class="rounded-lg bg-slate-50 px-4 py-3 text-sm text-primary hover:no-underline"
                >
                  Select your preferred payment method
                </AccordionTrigger>
                <AccordionContent class="px-2 py-0">
                  <slot name="payment-option" />
                </AccordionContent>
              </AccordionItem>
            </template>
          </Accordion>
        </template>
      </TabsContent>
      <TabsContent :value="tabs[1]">
        <template v-for="(value, key) in latestBillApproval" :key="key">
          <div
            v-if="value !== null"
            class="flex items-center justify-between border-b py-2 text-sm text-primary"
          >
            <div>{{ key }}</div>
            <div class="w-2/3 truncate text-right font-medium capitalize">
              {{ value }}
            </div>
          </div>
        </template>
        <template v-if="bill && bill.notes.length > 0">
          <div class="my-4">
            <p class="my-2 text-sm text-primary">Notes</p>
            <ModulesBillsApprovalNotes v-if="bill" :notes="bill.notes" />
          </div>
        </template>
      </TabsContent>
    </Tabs>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import _ from 'lodash'
import dayjs from 'dayjs'
import { useToast } from '@/components/ui/toast'
import { formatDate, getFileType } from '@/lib/utils'
import { setModalState } from '@/services/modal'
import type { Invoice } from '@/shared/interfaces'
import { useBillsStore } from '@/stores/bills'
import TheIcon from '@/components/shared/the-icon.vue'
import { Skeleton } from '~/components/ui/skeleton'
import { routes } from '~/utils/routes'
import { useProfileStore } from '@/stores/profile'

const props = defineProps<{
  bill: Invoice | null
  preview: boolean
  canMakePayment: boolean
}>()

const emit = defineEmits(['hasFetchedFiles', 'duplicateBill'])

const mappedAccount = ref<{ key: string; value: string }[]>([])
const uploadedFiles = ref<(File | null)[]>([])
const isLoadingUploadedFiles = ref(true)
const fileCount = ref(4)
const activeSection = ref('item-1')

const { toast } = useToast()
const { $event, $lenkieBankingApi } = useNuxtApp()
const profileStore = useProfileStore()
const billStore = useBillsStore()
const route = useRoute()
const router = useRouter()

const canUpdateGBPVendors = computed(() => {
  const { bill } = props
  return (
    bill?.currency === 'GBP' &&
    bill.beneficiary?.account?.uk_bank_details !== null &&
    (bill.bill_status === 'ReadyForPayment' || bill?.bill_status === 'Canceled')
  )
})

const handleEditBill = () => {
  if (props.bill !== null) {
    billStore.setSelectedBill(props.bill)
    billStore.setSelectedBillInvoices(uploadedFiles.value)

    if (route.path !== routes.newBill) {
      router.push({
        path: routes.newBill,
        query: {
          id: props.bill.id,
        },
      })
    } else {
      billStore.setShowEditBill(true)
    }
  }
}

const downloadFile = (file: File | null) => {
  if (file != null) {
    const link = document.createElement('a')
    link.setAttribute('download', file.name)
    link.href = URL.createObjectURL(file)
    document.body.appendChild(link)
    link.click()
    link.remove()
    return toast({
      title: 'File downloaded successfully',
    })
  }
}

const handleSelectedInvoice = (file: File | null) => {
  if (file !== null) {
    setModalState({ isOpen: true, type: 'fileUpload', selectedInvoice: file })
  }
}

const showMoreInvoices = () => {
  if (fileCount.value !== uploadedFiles.value.length) {
    fileCount.value += 2
  } else {
    fileCount.value = uploadedFiles.value.length
  }
}

const fetchFiles = async (files: string[]) => {
  try {
    const sellerId = profileStore.data.sellerId
    const uploadedFiles = await Promise.all(
      files.map(async (el) => {
        const fileName = el
          .split(`organisations/${sellerId}/bill/invoices/`)
          .join('')
        const type = getFileType(el)

        try {
          const response = await $lenkieBankingApi.get(
            `/FileUpload/downloadFile?filePath=${el}`,
            {
              responseType: 'blob',
            },
          )
          const blob = new Blob([response.data], {
            type: response.headers['content-type'],
          })
          return new File([blob], fileName, {
            type,
          })
        } catch (error) {
          isLoadingUploadedFiles.value = false
          emit('hasFetchedFiles', {
            fetched: false,
            files: [],
          })
          return null
        }
      }),
    )
    return uploadedFiles.filter((file) => file !== null)
  } catch (error) {
    return []
  }
}

const updateBillDetails = async () => {
  if (props.bill !== null) {
    activeSection.value =
      props.bill?.bill_status === 'ReadyForPayment' ? 'item-2' : 'item-1'
    if (props.bill.beneficiary !== null) {
      if (props.bill.beneficiary.account !== null) {
        const map =
          props.bill?.beneficiary.account.international_bank_account_details ??
          props.bill.beneficiary.account.uk_bank_details ??
          props.bill.beneficiary?.account.nonuk_bank_account_details ??
          props.bill.beneficiary.account.ach_bank_details ??
          props.bill.beneficiary.account.fedwire_bank_account_details
        mappedAccount.value = Object.entries(map).map(([k, v]) => {
          return {
            key:
              k.replaceAll('_', ' ').charAt(0).toUpperCase() +
              k.replaceAll('_', ' ').slice(1),
            value:
              k === 'sort_code'
                ? (String(v).match(/.{1,2}/g) || []).join('-')
                : v,
          }
        })
      }
    }

    if (props.bill?.invoice_url && props.bill?.invoice_url !== null) {
      const files = props.bill?.invoice_url.split(',')
      uploadedFiles.value = await fetchFiles(files)
      isLoadingUploadedFiles.value = false
      emit('hasFetchedFiles', {
        fetched: false,
        files: uploadedFiles.value,
      })
    } else {
      isLoadingUploadedFiles.value = false
      emit('hasFetchedFiles', {
        fetched: false,
        files: [],
      })
    }
  }
}

const openEditVendor = () => {
  if (!props.bill?.beneficiary) {
    return
  }

  billStore.$patch({
    selectedBillInvoices: uploadedFiles.value,
    vendorPrefill:
      props.bill?.beneficiary.name || props.bill?.beneficiary.trading_name,
  })
  $event('open:vendor', {
    trigger: true,
    createNewVendorForBill: true,
    updateVendorType: {
      type: 'update',
      beneficiary: props.bill?.beneficiary,
    },
  })
}

const goToDuplicateBill = (billId: string | null) => {
  if (billId && billId !== null) {
    if (route.path !== routes.newBill) {
      $event('bill-table:duplicate', {
        trigger: true,
        duplicateBillId: billId,
      })
    } else {
      $event('bill:duplicate', {
        trigger: true,
        duplicateBillId: billId,
      })
    }
  }
}

watch(
  () => props.bill,
  () => {
    updateBillDetails()
  },
  { immediate: true, deep: true },
)
const { currentPermissionsIds } = useUserRole()

const permissionsNeededHere = computed(() => {
  const permissionsSet = new Set(currentPermissionsIds.value)

  return {
    canSeePaymentOption:
      permissionsSet.has('CardPay.MakePayment') ||
      permissionsSet.has('OpenBanking.MakePayment') ||
      permissionsSet.has('CreditFacility.MakePayment'),
  }
})

const latestBillApproval = computed(() => {
  const bill = props.bill

  const result: Record<
    | 'Requested by'
    | 'Selected approver'
    | 'Approved by'
    | 'Declined by'
    | 'Approved on'
    | 'Declined on'
    | 'Approval Status',
    null | string
  > = {
    'Requested by': null,
    'Selected approver': null,
    'Approved by': null,
    'Declined by': null,
    'Approved on': null,
    'Declined on': null,
    'Approval Status': null,
  }

  if (
    !bill ||
    !bill.approvals ||
    bill.approvals.length === 0 ||
    !bill.recent_approval
  )
    return result

  const formatFullName = (person: {
    given_name: string
    family_name: string
  }) => `${person.given_name} ${person.family_name}`

  const formatDate = (date: string | null) =>
    date ? dayjs(date).format('DD-MM-YYYY h:mmA') : null

  const recentApproval = bill.recent_approval

  if (!recentApproval) return result

  const fullNameOfRequester = formatFullName(recentApproval.requested_by)
  const fullNameOfWhoWasAskedToApprove = formatFullName(recentApproval.approval)

  result['Requested by'] = fullNameOfRequester
  result['Selected approver'] = fullNameOfWhoWasAskedToApprove

  if (
    recentApproval.is_approved === null ||
    bill.bill_status === 'AwaitingApproval'
  )
    return result

  const fullNameOfWhoApproved = formatFullName(recentApproval.approved_by)
  const timeOfApprovalOrRejection = formatDate(recentApproval.updated_at)

  if (recentApproval.is_approved === true) {
    result['Approved by'] = fullNameOfWhoApproved
    result['Approved on'] = timeOfApprovalOrRejection
    result['Approval Status'] = 'Approved'
  } else if (recentApproval.is_approved === false) {
    result['Declined by'] = fullNameOfWhoApproved
    result['Declined on'] = timeOfApprovalOrRejection
    result['Approval Status'] = 'Declined'
  }

  return result
})

const showApprovalDetailsTab = computed(() => {
  const bill = props.bill
  if (!bill) return false
  return bill.approvals.length > 0
})

const tabs = ['Invoice details', 'Approval details'] as const
const activeTab = ref<(typeof tabs)[number]>('Invoice details')
</script>
